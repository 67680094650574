
import axios from 'axios';
import config from '../config/reg.config';
import { IGetBusinessUserDetails, IGetDiscountCode, IGetProductDetails, IPatchEmailConfirm, IPostBusinessUserDetails } from '../types/userDetails';


// --------------- Queries ------------- //

export const getBusinessUserDetails = async (userEmail: any) => {
  const url = config.API.registration.business.getBusinessUserDetails
    .replace(':userEmail', userEmail);
  const { data }: {data: IGetBusinessUserDetails} = await axios.get(url);

  return data;
};
export const getProductDetails = async () => {
  const url = config.API.registration.business.getProductDetails
  const { data }: {data: IGetProductDetails[]} = await axios.get(url);

  return data;
};
export const getDiscountCode = async (promoName: string | any) => {
  const url = config.API.registration.business.getDiscountCode.replace(':promoName', promoName)
  const { data }: {data: IGetDiscountCode[]} = await axios.get(url);

  return data;
};






// --------------- Mutations ------------- //

export const createBusinessUserStore = (store: IPostBusinessUserDetails) => {
  const url = config.API.registration.business.postBusinessDetails;
  return axios.post(url, store);
};

export const confirmBusinessUser = (store: IPatchEmailConfirm) => {
  const url = config.API.registration.business.confirmEmail;
  return axios.patch(url, store);
};

