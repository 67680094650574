let DashboardAPI;
const env = process.env.TARGET_ENV;
if (env === 'development') {
  DashboardAPI = 'https://portal.dev.2132qsr.com';
} else if (env === 'production') {
  DashboardAPI = 'https://portal.ev.pebble.tech';
}  else if (env === 'staging') {
  DashboardAPI = 'https://portal.staging.2132qsr.com';
} else if (env === 'local') {
  DashboardAPI = 'http://localhost:3002';
}


const config = {
    API: {
      registration: {
        baseUrl: DashboardAPI,
        business: {
            postBusinessDetails: `https://portal.staging.2132qsr.com/registration/createUser`,
            getBusinessUserDetails: `https://portal.staging.2132qsr.com/registration/business-user-details/:userEmail`,
            confirmEmail: `https://portal.staging.2132qsr.com/registration/enable-system-user`,
            getProductDetails: `https://portal.staging.2132qsr.com/registration/products`,
            getDiscountCode: `https://portal.staging.2132qsr.com/payment/validate-promo-code/:promoName`,
        },
        payment: {
            postPaymentIntent: `https://portal.staging.2132qsr.com/payment/create-intent`,
            postPaymentSession: `https://portal.staging.2132qsr.com/payment/create-checkout-session`
        }
    }
}

}

export default config;
