import { Button, Checkbox, Form, Input } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { FC, useState } from "react";
import { useCreateBusinessUser } from "../../../hooks/postHooks";
import { useGlobalContext } from "../../../store/pageStore/pageStore";
import styles from "./UserDetailsForm.module.scss";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../Modals/ErrorModal/ErrorModal";

const UserDetailsForm: FC = () => {
  const {
    details,
    setDetails,
    setUserModalOpen,
    storeNumber,
    freeAccount,
    setPage,
    setErrorMessage,
    setErrorModalOpen,
  } = useGlobalContext();

  const [confirmLoading, setConfirmLoading] = useState(false);

  const createBusinessUser = useCreateBusinessUser();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const onEmailChange = (e: CheckboxChangeEvent) => {
    form.setFieldsValue({
      userEmail: details.companyEmail,
    });

    setDetails({ ...details, userEmail: details.companyEmail });
  };

  const onPhoneChange = (e: CheckboxChangeEvent) => {
    form.setFieldsValue({
      userPhoneNumber: details.companyPhoneNumber,
    });

    setDetails({ ...details, userPhoneNumber: details.companyPhoneNumber });
  };

  const onFinish = () => {
    form.getFieldsValue(true);
    const store = {
      businessPlan: details.userPlan,
      numberOfStores: parseInt(storeNumber),
      companyName: details.companyName,
      userEmailAddress: details.userEmail,
      businessEmailAddress: details.companyEmail,
      firstName: details.firstName,
      lastName: details.lastName,
      userPhoneNumber: details.userPhoneNumber,
      businessPhoneNumber: details.companyPhoneNumber,
      addressLine1: details.addressLineOne,
      addressLineTwo: details.addressLineTwo,
      city: details.city,
      state: details.state,
      postcode: details.zipCode,
      password: details.password,
      active: false,
      paymentStatus: "new_account",
      freeAccount: freeAccount,
    };

    setConfirmLoading(true);
    setTimeout(() => {
      createBusinessUser.mutate(store, {
        onSuccess: () => {
          setConfirmLoading(false);
          if (freeAccount === true) {
            navigate("/account-confirmed");
          } else {
            setPage("loginDetailsConfirm");
          }
          console.log(store);
        },
        onError: () => {
          setErrorMessage(
            "There seemed to be a problem! Please go back and try again!"
          );
          setConfirmLoading(false);
          setErrorModalOpen(true);
        },
      });
    }, 1000);
  };
  return (
    <>
      <div className={styles.businessForm}>
        <p className={styles.subHeaderOne}>
          Please Provide the Following Information:
        </p>
        <Form
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          className={styles.formArea}
          initialValues={details}>
          <div className={styles.formAreaBase}>
            <div className={styles.formAreaLeft}>
              <p className={styles.title}>First Name</p>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "First Name is required",
                  },
                ]}>
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      firstName: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <p className={styles.title}>Your Email Address</p>
              <Form.Item valuePropName="checked">
                <Checkbox className={styles.checkBox} onChange={onEmailChange}>
                  <p className={styles.checkBoxText}>
                    Use Business Email Address?
                  </p>
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="userEmail"
                rules={[
                  {
                    required: true,
                    message: "User Email is required",
                  },
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}>
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      userEmail: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>

            <div className={styles.formAreaRight}>
              <p className={styles.title}>Last Name</p>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Last Name is required",
                  },
                ]}>
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      lastName: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <p className={styles.title}>Your Phone Number</p>
              <Form.Item valuePropName="checked">
                <Checkbox className={styles.checkBox} onChange={onPhoneChange}>
                  <p className={styles.checkBoxText}>
                    Use Business Phone Number?
                  </p>
                </Checkbox>
              </Form.Item>

              <Form.Item
                name="userPhoneNumber"
                rules={[
                  {
                    required: true,
                    message: "User Phone Number is required",
                  },
                ]}>
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      userPhoneNumber: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>
          </div>

          <p className={styles.subHeaderTwo}>
            Choose a safe and memorable password. This, in combination with the
            email address you provided above, will become your login credentials
            for accessing your Pebble dashboard.
          </p>
          <div className={styles.formAreaBase}>
            <div className={styles.formAreaLeft}>
              <p className={styles.title}>Password</p>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },

                  {
                    pattern: new RegExp(/^(?=.*\d)[\s\S]{10,}$/),
                    message:
                      "Password must be a minimum of 10 characters with at least 1 number.",
                  },
                ]}
                hasFeedback>
                <Input.Password
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      password: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>

            <div className={styles.formAreaRight}>
              <p className={styles.title}>Confirm Password</p>
              <Form.Item
                name="confirm"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Passwords do not match!")
                      );
                    },
                  }),
                ]}>
                <Input.Password />
              </Form.Item>
            </div>
          </div>

          {/* <p className={styles.subHeaderTwo}>Test</p> */}
          <div className={styles.button}>
            {confirmLoading ? (
              <Button loading={true} htmlType="submit">
                Creating Account!
              </Button>
            ) : (
              <Button htmlType="submit">Create Account!</Button>
            )}
          </div>
        </Form>
        <ErrorModal />
      </div>
    </>
  );
};

export default UserDetailsForm;
