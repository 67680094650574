import { useMutation, useQueryClient } from 'react-query';
import { postPaymentIntent, postPaymentSession } from '../store/postRequests/paymentRequests';


export enum EStoreKey {
    PAYMENT_INTENT = 'payment-intent',
    PAYMENT_SESSION = 'payment-session'
  }

export const usePostPaymentIntent = () => {
    const queryClient = useQueryClient();
  
    return useMutation(postPaymentIntent, {
      onSuccess: () => {
        queryClient.invalidateQueries(EStoreKey.PAYMENT_INTENT);
      },
    });
  };

export const usePostPaymentSession = () => {
    const queryClient = useQueryClient();
  
    return useMutation(postPaymentSession, {
      onSuccess: () => {
        queryClient.invalidateQueries(EStoreKey.PAYMENT_SESSION);
      },
    });
  };
