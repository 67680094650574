import { createContext, useContext } from "react"

export type GlobalContent = {
  storeNumber: any
  setStoreNumber:(s: any) => void

  discountFetch: boolean
  setDiscountFetch:(f: boolean) => void

  userModalOpen: boolean
  setUserModalOpen:(u: boolean) => void

  freeAccount: boolean
  setFreeAccount:(a: boolean) => void

  errorModalOpen: boolean
  setErrorModalOpen:(a: boolean) => void

  errorMessage: string
  setErrorMessage:(m: string) => void

  page: string
  setPage:(c: string) => void

  details: any
  setDetails:(d: any) => void

  productDetails: any
  setProductDetails:(p: any) => void
}

export const MyGlobalContext = createContext<GlobalContent>({

storeNumber: '0',
setStoreNumber: () => {},

discountFetch: false,
setDiscountFetch: () => {},

errorModalOpen: false,
setErrorModalOpen: () => {},

userModalOpen: false,
setUserModalOpen: () => {},

freeAccount: false,
setFreeAccount: () => {},

errorMessage: '',
setErrorMessage: () => {},

page: 'loginDetails',
setPage: () => {},

details: {},
setDetails: () => {},

productDetails: {},
setProductDetails: () => {},
})

export const useGlobalContext = () => useContext(MyGlobalContext)