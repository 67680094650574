import { Button, Form, Input } from "antd";
import valZip from "val-zip";

import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../store/pageStore/pageStore";
import styles from "./BusinessDetailsForm.module.scss";

const BusinessDetailsForm: FC = () => {
  const { details, setDetails } = useGlobalContext();

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async () => {
    form.validateFields();
    navigate("/user-details");
  };

  return (
    <>
      <div className={styles.businessForm}>
        <p className={styles.selectedPlan}>
          Selected Plan:
          <span className={styles.selectedPlan_plan}>
            {" "}
            {details.userPlan.toUpperCase()}
          </span>
        </p>

        <Form
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          className={styles.formArea}
          initialValues={details}>
          <div className={styles.formAreaBase}>
            <div className={styles.formAreaLeft}>
              <p className={styles.title}>Business Name</p>
              <Form.Item
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Company Name is required",
                  },
                ]}>
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      companyName: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <p className={styles.title}>Business Email</p>
              <Form.Item
                name="companyEmail"
                rules={[
                  {
                    required: true,
                    message: "Company Email is required",
                  },
                  {
                    type: "email",
                    message: "This is not a valid E-mail!",
                  },
                ]}>
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      companyEmail: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <p className={styles.title}>Business Phone Number</p>
              <Form.Item
                name="companyPhoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Company Phone Number is required",
                  },
                  {
                    pattern: new RegExp(
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
                    ),
                    message: "Please enter a valid phone number",
                  },
                ]}>
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      companyPhoneNumber: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <p className={styles.title}>Address Line 1</p>
              <Form.Item
                name="addressLineOne"
                rules={[
                  {
                    required: true,
                    message: "Company Address is required",
                  },
                ]}>
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      addressLineOne: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>

            <div className={styles.formAreaRight}>
              <p className={styles.title}>Address Line 2</p>
              <Form.Item
                name="addressLineTwo"
                rules={[
                  {
                    required: false,
                  },
                ]}>
                <Input
                  placeholder="Optional"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      addressLineTwo: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <p className={styles.title}>City</p>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: "City is required",
                  },
                ]}>
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      city: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <p className={styles.title}>State</p>
              <Form.Item
                name="state"
                rules={[
                  {
                    required: true,
                    message: "State is required",
                  },
                ]}>
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      state: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <p className={styles.title}>Zip Code</p>
              <Form.Item
                name="zipCode"
                rules={[
                  {
                    required: true,
                    message: "Zip Code is required",
                  },

                  {
                    message: "Please enter a valid Zip Code",
                    validator: (_, value) => {
                      if (valZip(value, "US") === true) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Something went wrong!");
                      }
                    },
                  },
                ]}>
                <Input
                  placeholder="Required"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      zipCode: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>
          </div>

          <div className={styles.button}>
            <Button htmlType="submit">Continue</Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default BusinessDetailsForm;
