import { FC, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./App.scss";
import Header from "./Components/Header/Header";
import BusinessDetailsPage from "./pages/BusinessDetails/BusinessDetailsPage";
import ChoosePlanPage from "./pages/ChoosePlan/ChoosePlanPage";
import FinishPage from "./pages/FinishPage/FinishPage";
import PaymentDetailsPage from "./pages/PaymentDetails/PaymentDetailsPage";
import UserSection from "./pages/UserSection/UserSection";
import { MyGlobalContext } from "./store/pageStore/pageStore";
import { IGetProductDetails, userDetails } from "./store/types/userDetails";
import { QueryClient, QueryClientProvider } from "react-query";
import EmailConfirmedPage from "./pages/EmailConfirmed/EmailConfirmedPage";
import FreeCouponFinishPage from "./pages/FreeCouponFinishPage/FreeCouponFinishPage";

const queryClient = new QueryClient();

const blankDetails = {
  companyName: "",
  companyEmail: "",
  userEmail: "",
  firstName: "",
  lastName: "",
  companyPhoneNumber: "",
  userPhoneNumber: "",
  addressLineOne: "",
  addressLineTwo: "",
  city: "",
  state: "",
  zipCode: "",
  password: "",
  promoCodeId: "",
  userPlan: "Activate",
  percentOff: null,
  promoName: "",
};

const blankProductDetails = {
  id: 0,
  stripePriceId: "",
  name: "",
  price: "",
  created: "",
  active: false,
  specs: [""],
};

const App: FC = () => {
  const [page, setPage] = useState<string>("userDetails");
  const [storeNumber, setStoreNumber] = useState<string | number>("1");
  const [userModalOpen, setUserModalOpen] = useState<boolean>(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [discountFetch, setDiscountFetch] = useState<boolean>(false);
  const [freeAccount, setFreeAccount] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    "Oops! Something went wrong! Please try again!"
  );
  const [details, setDetails] = useState<userDetails>(blankDetails);
  const [productDetails, setProductDetails] =
    useState<IGetProductDetails>(blankProductDetails);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <MyGlobalContext.Provider
          value={{
            page,
            setPage,
            discountFetch,
            setDiscountFetch,
            freeAccount,
            setFreeAccount,
            userModalOpen,
            setUserModalOpen,
            errorModalOpen,
            setErrorModalOpen,
            errorMessage,
            setErrorMessage,
            storeNumber,
            setStoreNumber,
            details,
            setDetails,
            productDetails,
            setProductDetails,
          }}>
          <div className="App">
            <Header />
            <Routes>
              <Route index element={<ChoosePlanPage />} />
              <Route
                path="/business-details"
                element={<BusinessDetailsPage />}
              />
              <Route path="/user-details" element={<UserSection />} />
              <Route path="/email-confirmed" element={<EmailConfirmedPage />} />
              <Route path="/payment-details" element={<PaymentDetailsPage />} />
              <Route path="/confirm" element={<FinishPage />} />
              <Route
                path="/account-confirmed"
                element={<FreeCouponFinishPage />}
              />
            </Routes>
          </div>
        </MyGlobalContext.Provider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
