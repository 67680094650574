import { FC, useEffect } from "react";

import Finish from "../../Components/ProgressBar/Finish/Finish";
import styles from "./FreeCouponFinishPage.module.scss";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import { Button } from "antd";

const FreeCouponFinishPage: FC = () => {
  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <>
      {/* Progress Bar */}
      <Finish />
      {/* Progress Bar */}

      <SectionHeader>
        <span className={styles.header}>Account Confirmed!</span>
      </SectionHeader>
      <p className={styles.title}>
        You can now login to your company dashboard and turbocharge your
        engagement!
      </p>

      <div className={styles.buttonContainer}>
        <a href="https://ev.pebble.tech/login" target="_blank" rel="noreferrer">
          <Button>LOGIN TO DASHBOARD</Button>
        </a>
      </div>
    </>
  );
};

export default FreeCouponFinishPage;
