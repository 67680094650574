
import axios from 'axios';
import config from '../config/reg.config';
import { IPostPaymentIntent, IPostPaymentSession } from '../types/paymentTypes';

// --------------- Queries ------------- //


// --------------- Mutations ------------- //

export const postPaymentIntent = async (data: IPostPaymentIntent) => {
    const url = config.API.registration.payment.postPaymentIntent;

    const { data: response } = await axios.post(url, data);

    return response

  };

export const postPaymentSession = async (data: IPostPaymentSession) => {
    const url = config.API.registration.payment.postPaymentSession;

    const { data: response } = await axios.post(url, data);

    return response

  };



