import { Button, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EmailConfirmed from "../../Components/ProgressBar/EmailConfirmed/EmailConfirmed";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import { usePostPaymentSession } from "../../hooks/paymentHooks";
import {
  useConfirmBusinessUser,
  useGetBusinessUserDetails,
} from "../../hooks/postHooks";
import { useGlobalContext } from "../../store/pageStore/pageStore";
import styles from "./EmailConfirmedPage.module.scss";

const EmailConfirmedPage: FC = () => {
  const {
    details,
    setDetails,
    setStoreNumber,
    setErrorModalOpen,
    setErrorMessage,
  } = useGlobalContext();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [spinActive, setSpinActive] = useState<boolean>(true);
  const [errorMode, setErrorMode] = useState<boolean>();
  const [searchParams] = useSearchParams();
  const confirmEmail = useConfirmBusinessUser();
  const postPaymentSession = usePostPaymentSession();

  const userEmail = searchParams.get("email");
  const token = searchParams.get("token");

  const { data } = useGetBusinessUserDetails(userEmail);

  const confirmEmailstore = {
    emailAddress: userEmail,
    confirmationToken: token,
  };

  useEffect(() => {
    confirmEmail.mutate(confirmEmailstore, {
      onSuccess: () => {
        setSpinActive(false);
        setErrorMode(false);
      },
      onError: () => {
        setSpinActive(false);
        setErrorMode(true);
      },
    });
  }, []);

  const onClick = async () => {
    setConfirmLoading(true);
    setDetails({
      ...details,
      userEmail: userEmail,
      companyName: data?.companyName,
      companyEmail: data?.emailAddress,
      companyPhoneNumber: data?.phoneNumber,
      userPlan: data?.businessPlan,
    });
    setStoreNumber(data?.numberOfStores);

    const postSessionStore = {
      stripeCustomerId: data?.stripeCustomerId,
      priceId: localStorage.getItem("stripePriceId"),
      quantity: data?.numberOfStores,
      promoCodeId: localStorage.getItem("promoCodeId"),
    };

    setTimeout(() => {
      postPaymentSession.mutate(postSessionStore, {
        onSuccess: (response) => {
          window.location.href = response.url;
          setConfirmLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setErrorMessage("Something went wrong when trying to access Stripe!");
          setErrorModalOpen(true);
        },
      });
    }, 600);
  };

  return (
    <>
      {/* Progress Bar */}
      <EmailConfirmed />
      {/* Progress Bar */}

      {spinActive && (
        <div className={styles.spinner}>
          <Spin size="large" />
        </div>
      )}

      {errorMode && (
        <>
          <SectionHeader>
            <span className={styles.errorTitle}>
              Oops! Something went wrong!
            </span>
          </SectionHeader>
          <p className={styles.title}>Sorry, this link is invalid!</p>
          <p className={styles.title}>
            Please return to the registration form and try again.
          </p>
        </>
      )}

      {errorMode === false && (
        <>
          <SectionHeader>Congratulations!</SectionHeader>
          <p className={styles.title}>
            Your email address has now been confirmed and your account has been
            set up!
          </p>
          <p className={styles.title}>Click below to proceed to payment.</p>
          <div className={styles.button}>
            {confirmLoading ? (
              <Button loading={true}>CONTINUE</Button>
            ) : (
              <Button onClick={onClick}>CONTINUE</Button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default EmailConfirmedPage;
