import { Button, Input, Select } from "antd";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../store/pageStore/pageStore";
import { planSelectOptions } from "../../store/selectOptions/planSelectOptions";
import DiscountCode from "../DiscountCode/DiscountCode";

import styles from "./PlanOptions.module.scss";

const PlanOptions: FC = () => {
  const {
    setStoreNumber,
    storeNumber,
    productDetails,
    setDiscountFetch,
    discountFetch,
    details,
    setDetails,
  } = useGlobalContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChange = (e: number | any) => {
    setStoreNumber(e);
  };

  const onDiscountClick = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setDiscountFetch(true);
      setIsLoading(false);
    }, 600);
  };

  const onSelectPlanClick = () => {
    if (details.userPlan === "Activate") {
      localStorage.setItem("stripePriceId", productDetails?.[0].stripePriceId);
    } else if (details.userPlan === "Pro") {
      localStorage.setItem("stripePriceId", productDetails?.[1].stripePriceId);
    } else if (details.userPlan === "Concierge") {
      localStorage.setItem("stripePriceId", productDetails?.[2].stripePriceId);
    }

    if (details.promoCodeId !== "") {
      localStorage.setItem("promoCodeId", details.promoCodeId);
    }
  };

  // Monthly & 12 Month Term Total price maths for all 3 tiers //

  //Activate
  const productActivate = productDetails?.[0];
  const totalPriceAct = +productActivate?.price;
  const monthlyAmountAct = totalPriceAct / 12;

  const monthlyTotalAct = monthlyAmountAct * parseInt(storeNumber);
  const termTotalAct = monthlyTotalAct * 12;

  //Pro
  const productPro = productDetails?.[1];
  const totalPricePro = +productPro?.price;
  const monthlyAmountPro = totalPricePro / 12;

  const monthlyTotalPro = monthlyAmountPro * parseInt(storeNumber);
  const termTotalPro = monthlyTotalPro * 12;

  //Concierge
  const productCon = productDetails?.[2];
  const totalPriceCon = +productCon?.price;
  const monthlyAmountCon = totalPriceCon / 12;

  const monthlyTotalCon = monthlyAmountCon * parseInt(storeNumber);
  const termTotalCon = monthlyTotalCon * 12;

  // Discount price maths for all 3 tiers //

  //Activate
  const percentageOffAct = termTotalAct / 100;
  const discountAmountAct = percentageOffAct * details.percentOff;
  const discountedPriceAct = termTotalAct - discountAmountAct;
  //Pro
  const percentageOffPro = termTotalPro / 100;
  const discountAmountPro = percentageOffPro * details.percentOff;
  const discountedPricePro = termTotalPro - discountAmountPro;
  //Concierge
  const percentageOffCon = termTotalCon / 100;
  const discountAmountCon = percentageOffCon * details.percentOff;
  const discountedPriceCon = termTotalCon - discountAmountCon;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.infoBlockContainer}>
          <div className={styles.infoBlock}>
            <header className={styles.infoBlock_header}>Company Stores</header>

            <p className={styles.infoBlock_message}>(# of Locations)</p>

            <div className={styles.infoBox}>
              <Select
                options={planSelectOptions}
                placeholder="Please Select.."
                defaultValue={storeNumber || 1}
                onChange={onChange}
              />
            </div>
          </div>

          <div className={styles.infoBlock}>
            <header className={styles.infoBlock_header}>Monthly Charge</header>
            <p className={styles.infoBlock_message}>(Plan x # of Locations)</p>
            <div className={styles.infoBox}>
              {" "}
              <p>
                <span className={styles.infoBox_cost}>
                  {details.userPlan === "Activate" &&
                    monthlyAmountAct.toFixed(2)}
                  {details.userPlan === "Pro" && monthlyAmountPro.toFixed(2)}
                  {details.userPlan === "Concierge" &&
                    monthlyAmountCon.toFixed(2)}
                </span>{" "}
                x {storeNumber} ={" "}
                <span className={styles.infoBox_monthlyCharge}>
                  {details.userPlan === "Activate" &&
                    `$${monthlyTotalAct.toFixed(2)}`}
                  {details.userPlan === "Pro" &&
                    `$${monthlyTotalPro.toFixed(2)}`}
                  {details.userPlan === "Concierge" &&
                    `$${monthlyTotalCon.toFixed(2)}`}
                </span>
              </p>{" "}
            </div>
          </div>

          <div className={styles.infoBlock}>
            <header className={styles.infoBlock_header}>Term Total</header>
            <p className={styles.infoBlock_message}>
              (Monthly Charge x 12 Months)
            </p>
            <div className={styles.infoBox}>
              {" "}
              <p>
                <span className={styles.infoBox_cost}>
                  {details.userPlan === "Activate" &&
                    `${monthlyTotalAct.toFixed(2)}`}
                  {details.userPlan === "Pro" &&
                    `${monthlyTotalPro.toFixed(2)}`}
                  {details.userPlan === "Concierge" &&
                    `${monthlyTotalCon.toFixed(2)}`}
                </span>{" "}
                x 12 ={" "}
                {details.percentOff !== null ? (
                  <>
                    <span className={styles.infoBox_termChargeStrike}>
                      {details.userPlan === "Activate" &&
                        `$${termTotalAct.toFixed(2)}`}
                      {details.userPlan === "Pro" &&
                        `$${termTotalPro.toFixed(2)}`}
                      {details.userPlan === "Concierge" &&
                        `$${termTotalCon.toFixed(2)}`}
                    </span>

                    <span className={styles.infoBox_termChargeNew}>
                      {details.userPlan === "Activate" &&
                        `$${discountedPriceAct.toFixed(2)}`}
                      {details.userPlan === "Pro" &&
                        `$${discountedPricePro.toFixed(2)}`}
                      {details.userPlan === "Concierge" &&
                        `$${discountedPriceCon.toFixed(2)}`}
                    </span>
                  </>
                ) : (
                  <span className={styles.infoBox_termCharge}>
                    {details.userPlan === "Activate" &&
                      `$${termTotalAct.toFixed(2)}`}
                    {details.userPlan === "Pro" &&
                      `$${termTotalPro.toFixed(2)}`}
                    {details.userPlan === "Concierge" &&
                      `$${termTotalCon.toFixed(2)}`}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className={styles.discountTitle}>
          <p className={styles.discountTitle_title}>Discount Code</p>
          <section className={styles.discountCode}>
            <div className={styles.discountCode_input}>
              {details.promoCodeId !== "" ? (
                <Input
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      promoName: e.target.value,
                    })
                  }
                  placeholder={"Optional"}
                  defaultValue={"" || details.promoName}
                  disabled
                />
              ) : (
                <Input
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      promoName: e.target.value,
                    })
                  }
                  placeholder={"Optional"}
                  defaultValue={"" || details.promoName}
                />
              )}
            </div>
            {details.promoCodeId !== "" ? (
              <Button disabled style={{ minWidth: "30%" }}>
                APPLY
              </Button>
            ) : details.promoName === "" ? (
              <Button disabled style={{ minWidth: "30%" }}>
                APPLY
              </Button>
            ) : isLoading ? (
              <Button loading style={{ minWidth: "30%" }}>
                APPLY
              </Button>
            ) : (
              <Button onClick={onDiscountClick} style={{ minWidth: "30%" }}>
                APPLY
              </Button>
            )}

            {discountFetch && <DiscountCode promoName={details.promoName} />}
          </section>
        </div>

        <p className={styles.disclaimer}>
          Have 25 or more stores? Contact{" "}
          <button
            onClick={() => (window.location.href = "mailto:sales@pebble.tech")}
            className={styles.disclaimer_link}>
            sales@pebble.tech
          </button>
        </p>

        <div className={styles.btn}>
          {details.userPlan !== "NONE" ? (
            <Link to="/business-details">
              <Button onClick={onSelectPlanClick} type="primary">
                Select This Plan
              </Button>
            </Link>
          ) : (
            <Button disabled>Select This Plan</Button>
          )}
        </div>
      </div>
    </>
  );
};

export default PlanOptions;
