import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/pebble-business-logo.png";

import styles from "./Header.module.scss";

const Header: FC = () => {
  const navigate = useNavigate();

  const onLogoClick = () => {
    navigate("/");
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <img
          className={styles.title_icon}
          alt="Logo"
          src={Logo}
          onClick={onLogoClick}
          style={{ cursor: "pointer" }}
        />
        <p className={styles.title_text}>Sign Up - Staging</p>
      </div>
    </div>
  );
};

export default Header;
